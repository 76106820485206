<template>
  <div>
    <DeviceForm ref="DeviceForm" @payload="onPayload" />
    <BranchForm ref="BranchForm" @close="getList" />
    <ConfirmModal ref="DeleteConfirmModal" @ok="onDelete"
      :title="$t('cloud.title.confirm_title')"
      :content="$t('cloud.title.confirm_delete_device')"
      :ok_text="$t('button.ok')"
      :cancel_text="$t('button.cancel')"
    />
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
        <Search @search="onSearch">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-storage" />
                <strong>{{$t('cloud.title.device')}}</strong>
                <span v-if="count > 0"> ({{count}}) </span>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="list"
                :fields="FIELDS"
                :items-per-page="10"
                hover
                clickable-rows
                @row-clicked="onRowClick"
              >
                <td slot="status" slot-scope="{item}" class="align-middle">
                  <CBadge :color="$utility.getBadgeColor(item.status)">{{$t(`cloud.title.status_${item.status}`)}}</CBadge>
                </td>
                <td slot="model" slot-scope="{item}">
                  {{item.model?item.model:"AIBridge Pro"}}
                </td>
                <td slot="num_ch" slot-scope="{item}">
                  {{item.num_ch?item.num_ch:"8"}}
                </td>
                <td slot="memo" slot-scope="{item}">
                  {{item.memo?item.memo:""}}
                </td>
                <td slot="swver" slot-scope="{item}">
                  {{item.swver?item.swver:""}}
                </td>
                <td slot="ops" slot-scope="{item, index}" class="text-center align-middle">
                  <el-dropdown trigger="click" @command="(command) => onOps(command, index, item)" @click.native.stop>
                    <span class="el-dropdown-link">
                      <i class="el-icon-more text-dark" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="branch" icon="el-icon-office-building">{{ $t('cloud.title.device_branch_connect') }}</el-dropdown-item>
                      <el-dropdown-item command="edit" icon="el-icon-edit-outline">{{ $t('button.device_mod') }}</el-dropdown-item>
                      <el-dropdown-item command="remote" disabled><CIcon name="cil-rss" size="custom" width="12px" height="12px" /> {{ $t('button.device_remote') }}</el-dropdown-item>
                      <el-dropdown-item command="delete" icon="el-icon-delete">{{ $t('button.device_remove') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </CDataTable>
            </CCardBody>
          </CCard>
        </Search>
        <CRow class="justify-content-end" v-if="pageTotal > 1">
          <CPagination
            :activePage.sync="pageCurrent"
            :pages="pageTotal"
            @update:activePage="onUpdatePage"
          >
          </CPagination>
          <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import DeviceForm from './DeviceForm.vue'
import BranchForm from './BranchForm.vue'
import { mapState } from 'vuex'

export default {
  name: 'Devices',
  components: {
    Search,
    ConfirmModal,
    DeviceForm,
    BranchForm
  },
  data () {
    return {
      FIELDS: [
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'status', label: this.$t('cloud.title.status')},
        {key: 'model', label: this.$t('cloud.title.model')},
        {key: 'num_ch', label: this.$t('cloud.title.license_num_ch'), _classes: 'align-middle'},
        {key: 'mac_addr', label: 'MAC'},
        {key: 'swver', label: this.$t('cloud.title.swver')},
        {key: 'memo', label: this.$t('cloud.title.note')},
        {key: 'branch_name', label: this.$t('cloud.title.branch')},
        {key: 'ops', label: 'Operations', _style: 'width: 100px;'}
      ],
      querying: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      initmod: null
    }
  },
  mounted() {
    this.initmod = this.$route.query.mod;
    this.getList();
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  methods: {
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/bridge/${query_string}`)
        .then(result => {
          this.querying = false;
          this.list = this.$utility.copy(result.data.list);
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;

          if (this.initmod) {
            const target = this.list.find(el => el.device_id === this.initmod);
            this.$refs.DeviceForm.open(target);
            this.initmod = null;
          }
        })
        .catch(e => {
          console.error(e);
          this.querying = false;
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onOps(cmd, index, item) {
      if (cmd === 'branch') {
        this.$refs.BranchForm.open(item);
      } else if (cmd === 'edit') {
        this.$refs.DeviceForm.open(item);
      } else if (cmd === 'transfer') {
        this.$refs.TransferForm.open(item.id);
      } else if (cmd === 'delete') {
        this.$refs.DeleteConfirmModal.open(item.device_id);
      }
    },
    onDelete(device_id) {
      axios.delete(`/api/bridge/${device_id}/`)
        .then(() =>{
          this.$notify.success({
            title: this.$t('cloud.title.request_ok_title'),
            message: this.$t('cloud.title.request_ok_content'),
            offset: 30
          });
          this.getList();
        })
        .catch((error) => {
          let error_msg = error.response.data.error;
          if (error.response.data.error_detail) {
            error_msg += `: ${error.response.data.error_detail}`;
          }
          this.$notify.error({
            title: this.$t('cloud.title.request_fail_title'),
            message: error_msg,
            offset: 30
          });
        })
    },
    onPayload(data) {
      console.log(data);
      if (!data.device_id) {
        this.$notify.warning({
          title: this.$t('cloud.title.request_fail_title'),
          message: this.$t('cloud.title.request_fail_content'),
          offset: 30
        });
        return;
      }
      this.$confirm(this.$t('cloud.title.confirm_edit'), this.$t('cloud.title.confirm_title'), {
        confirmButtonText:this.$t('button.device_mod'),
        cancelButtonText:this.$t('button.cancel'),
        type:'warning'
      })
      .then(() => {
        axios.put(`/api/bridge/${data.device_id}/`, data)
          .then(() =>{
            this.$notify.success({
              title: this.$t('cloud.title.request_ok_title'),
              message: this.$t('cloud.title.request_ok_content'),
              offset: 30
            });
            this.getList();
          })
          .catch(error =>{
            console.error(error);
            this.$notify.error({
              title: this.$t('cloud.title.request_fail_title'),
              message: this.$t('cloud.title.request_fail_content'),
              offset: 30
            });
          })
      })
    },
    onRowClick(item) {
      // const device_info_b64 = Buffer.from(JSON.stringify(item)).toString('base64');
      // this.$store.commit('set', ['device_info', item]);
      this.$router.push(`/views/devices?device_id=${item.device_id}`);
    }
  }
}
</script>
