//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import axios from 'axios';
import Search from '@/containers/Search.vue';
export default {
  name: 'DeviceBranchModal',
  components: {
    Search: Search
  },
  data: function data() {
    return {
      loading: false,
      show: false,
      search_text: null,
      device: null,
      check_all: false,
      add_list: [],
      list: [],
      ipp: 8,
      sort_column: 'id',
      sort_order: 'asc',
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      FIELDS: [{
        key: 'name',
        label: this.$t('cloud.title.name')
      }, {
        key: 'address',
        label: this.$t('cloud.title.address')
      }, {
        key: 'operation',
        label: this.$t('cloud.title.operation')
      }]
    };
  },
  methods: {
    open: function open(param) {
      this.device = this.$utility.copy(param);
      this.show = true;
      this.getData();
    },
    getData: function getData() {
      var _this = this;

      var params = {};
      params.page = this.pageCurrent;
      params.ipp = this.ipp;
      params.sort_column = this.sort_column;
      params.sort_order = this.sort_order;

      if (this.search_text) {
        params.search = this.search_text;
      }

      var query_string = '?' + qs.stringify(params);
      this.loading = true;
      axios.get("/api/branches/".concat(query_string)).then(function (result) {
        _this.pageTotal = result.data.page.total;
        _this.pageCurrent = result.data.page.current;
        _this.count = result.data.page.count;
        var l = result.data.list.map(function (el) {
          if (_this.add_list.find(function (al) {
            return al.id === el.id;
          })) {
            el.checked = true;
            return el;
          }

          el.checked = false;
          return el;
        });
        _this.list = _this.$utility.copy(l);
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this.loading = false;
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.check_all = false;
      this.getData();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getData();
    },
    onCancel: function onCancel() {
      this.$emit('close');
      this.param = null;
      this.show = false;
      this.list = [];
      this.ipp = 8;
      this.sort_column = 'id';
      this.sort_order = 'asc';
      this.pageTotal = 1;
      this.pageCurrent = 1;
      this.pageDirectSet = 1;
      this.count = 0;
    },
    onOkay: function onOkay() {
      this.$emit('ok', this.param); // const data = {};
      // const rels = [];
      // for (const lp of this.add_list) {
      //   rels.push({lp_id:lp.id,group_id:this.group.id});
      // }
      // data.rels = rels;
      // if (this.is_add) {
      //   data.op = 'bind';
      // } else {
      //   data.op = 'unbind';
      // }
      // axios.patch(`/api/apps/${this.$route.params.app_name}/group/`, data)
      //   .then(() => {
      //     this.$emit('reload');
      //     this.$notify.success({
      //       title: this.$t('msgbox.request_ok_title'),
      //       message: this.$t('msgbox.request_ok_content'),
      //       offset: 30
      //     });
      //   })
      //   .catch(() => {
      //     this.$notify.error({
      //       title: this.$t('msgbox.request_fail_title'),
      //       message: this.$t('msgbox.request_fail_content'),
      //       offset: 30
      //     });
      //   })

      this.onCancel();
    },
    // onCheck(lp) {
    //   if (lp.checked) {
    //     this.add_list.push(this.$utility.copy(lp));
    //   } else {
    //     const pos = this.add_list.findIndex(el=>el.id === lp.id);
    //     this.add_list.splice(pos, 1);
    //   }
    //   this.list = this.$utility.copy(this.list);
    // },
    // onRemove(lp) {
    //   const pos = this.add_list.findIndex(el=>el.id === lp.id);
    //   this.add_list.splice(pos, 1);
    //   const list = this.$utility.copy(this.list);
    //   const l = list.map(el => {
    //     if (this.add_list.find(al => {
    //       return al.id === el.id;
    //     })) {
    //       el.checked = true;
    //       return el;
    //     }
    //     el.checked = false;
    //     return el;
    //   });
    //   this.list = this.$utility.copy(l);
    // },
    // onCheckAll() {
    //   if (this.check_all) {
    //     for (const lp of this.list) {
    //       const pos = this.add_list.findIndex(el => el.id === lp.id);
    //       if (pos >= 0) continue;
    //       this.add_list.push(this.$utility.copy(lp));
    //     }
    //   } else {
    //     for (const lp of this.list) {
    //       const pos = this.add_list.findIndex(el => el.id === lp.id);
    //       if (pos < 0) continue;
    //       this.add_list.splice(pos, 1);
    //     }
    //   }
    //   const list = this.$utility.copy(this.list);
    //   const l = list.map(el => {
    //     if (this.add_list.find(al => {
    //       return al.id === el.id;
    //     })) {
    //       el.checked = true;
    //       return el;
    //     }
    //     el.checked = false;
    //     return el;
    //   });
    //   this.list = this.$utility.copy(l);
    // },
    onSearch: function onSearch(text) {
      this.search_text = text;
      this.getData();
    },
    onBranch: function onBranch(branch) {
      var _this2 = this;

      var req = null;

      if (this.device.branch_id === branch.id) {
        req = axios.delete("/api/bridge/".concat(this.device.device_id, "/branch/"));
      } else {
        req = axios.post("/api/bridge/".concat(this.device.device_id, "/branch/"), {
          branch_sid: branch.sid
        });
      }

      req.then(function (response) {
        _this2.device = _this2.$utility.copy(response.data);

        _this2.$notify.success({
          title: _this2.$t('msgbox.request_ok_title'),
          message: _this2.$t('msgbox.request_ok_content'),
          offset: 30
        });
      }).catch(function (error) {
        console.error(error);

        _this2.$notify.error({
          title: _this2.$t('msgbox.request_fail_title'),
          message: error.response.data.error_detail,
          offset: 30
        });
      });
    }
  }
};