<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    :color="color"
    :size="size"
  >
    {{ content }}
    <template #header>
      <h6 class="modal-title text-dark">{{ title }}</h6>
      <CButtonClose @click="show = false" class="text-dark" />
    </template>
    <template #footer>
      <CButton @click="onOkay" color="primary">{{ok_text}}</CButton>
      <CButton @click="onCancel" color="secondary" v-if="cancel_text !== null">{{cancel_text}}</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    enable_close_btn: {type:Boolean, default:false},
    title: { type: String, default: 'Confirm' },
    content: { type: String, default: 'Are you sure?' },
    color: {type:String, default: 'light'},
    size: { type: String, default: 'sm' },
    ok_text: {type: String, default: 'OK'},
    cancel_text: {type: String, default: 'Cancel'}
  },
  data() {
    return {
      show: false,
      param: null,
      cb_ok: null,
      cb_cancel: null
    }
  },
  methods: {
    open(param, cb_ok, cb_cancel) {
      this.param = param;
      this.show = true;
      this.cb_ok = cb_ok;
      this.cb_cancel = cb_cancel;
    },
    onCancel() {
      this.$emit('cancel');
      if (this.cb_cancel) {
        this.cb_cancel();
      }
      this.cb_ok = null;
      this.cb_cancel = null;
      this.param = null;
      this.show = false;
    },
    onOkay() {
      this.$emit('ok', this.param);
      if (this.cb_ok) {
        this.cb_ok(this.param);
      }
      this.cb_ok = null;
      this.cb_cancel = null;
      this.show = false;
      this.param = null;
    }
  }
}
</script>
