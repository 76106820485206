var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DeviceForm',{ref:"DeviceForm",on:{"payload":_vm.onPayload}}),_c('BranchForm',{ref:"BranchForm",on:{"close":_vm.getList}}),_c('ConfirmModal',{ref:"DeleteConfirmModal",attrs:{"title":_vm.$t('cloud.title.confirm_title'),"content":_vm.$t('cloud.title.confirm_delete_device'),"ok_text":_vm.$t('button.ok'),"cancel_text":_vm.$t('button.cancel')},on:{"ok":_vm.onDelete}}),_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"xl":"12","xxl":"10"}},[_c('Search',{on:{"search":_vm.onSearch}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-storage"}}),_c('strong',[_vm._v(_vm._s(_vm.$t('cloud.title.device')))]),(_vm.count > 0)?_c('span',[_vm._v(" ("+_vm._s(_vm.count)+") ")]):_vm._e()]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.list,"fields":_vm.FIELDS,"items-per-page":10,"hover":"","clickable-rows":""},on:{"row-clicked":_vm.onRowClick},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"align-middle"},[_c('CBadge',{attrs:{"color":_vm.$utility.getBadgeColor(item.status)}},[_vm._v(_vm._s(_vm.$t(("cloud.title.status_" + (item.status)))))])],1)}},{key:"model",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.model?item.model:"AIBridge Pro")+" ")])}},{key:"num_ch",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.num_ch?item.num_ch:"8")+" ")])}},{key:"memo",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.memo?item.memo:"")+" ")])}},{key:"swver",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.swver?item.swver:"")+" ")])}},{key:"ops",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"text-center align-middle"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":function (command) { return _vm.onOps(command, index, item); }},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more text-dark"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"branch","icon":"el-icon-office-building"}},[_vm._v(_vm._s(_vm.$t('cloud.title.device_branch_connect')))]),_c('el-dropdown-item',{attrs:{"command":"edit","icon":"el-icon-edit-outline"}},[_vm._v(_vm._s(_vm.$t('button.device_mod')))]),_c('el-dropdown-item',{attrs:{"command":"remote","disabled":""}},[_c('CIcon',{attrs:{"name":"cil-rss","size":"custom","width":"12px","height":"12px"}}),_vm._v(" "+_vm._s(_vm.$t('button.device_remote')))],1),_c('el-dropdown-item',{attrs:{"command":"delete","icon":"el-icon-delete"}},[_vm._v(_vm._s(_vm.$t('button.device_remove')))])],1)],1)],1)}}])})],1)],1)],1),(_vm.pageTotal > 1)?_c('CRow',{staticClass:"justify-content-end"},[_c('CPagination',{attrs:{"activePage":_vm.pageCurrent,"pages":_vm.pageTotal},on:{"update:activePage":[function($event){_vm.pageCurrent=$event},_vm.onUpdatePage],"update:active-page":function($event){_vm.pageCurrent=$event}}}),_c('CInput',{staticClass:"px-3 w-auto",staticStyle:{"max-width":"120px"},attrs:{"type":"number"},on:{"change":_vm.onChangePageDirectSet},model:{value:(_vm.pageDirectSet),callback:function ($$v) {_vm.pageDirectSet=$$v},expression:"pageDirectSet"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }